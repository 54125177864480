<template>
  <div class="wrapper">
    <popup close-on-click-overlay @close="close"
      v-if="directoryList.length"
      v-model="show"
      position="left"
      duration="0.2"
      class="directory-block"
      :lock-scroll="false"
    >
      <div class="header">
        <div class="header-name">目录</div>
        <div class="chapter" @click="directorySort=!directorySort">
          <div class="chapter-text">共{{ directoryList.length }}章</div>
          <div class="chapter-icon" :class="{asc: directorySort, desc: !directorySort}"></div>
        </div>
      </div>
      <scroll ref="scroll" class="scroll" :data="data">
        <div>
          <div class="directory-list">
            <div
              v-for="(item,index) in directoryList"
              :key="index"
              class="directory-item" :class="{'isactive':nowCid == item.page?true:false}"
              @click="changePage(item.page)"
            >
              {{ item.name }}
              <span class="tip" v-if="item.hastag">已添加标签</span>
            </div>
          </div>
        </div>
      </scroll>
    </popup>
  </div>
</template>

<script>
import { Popup, Toast } from 'vant'
import Scroll from '@/components/scroll/scroll'

export default {
  name: 'EbookDetailQqMulu',
  props: {
    showDirectory: {
      type: Boolean,
      default: false
    },
    directoryList: {
      type: Array,
      default () {
        return []
      }
    },
    favoritesActive: {
      type: Boolean,
      default: false
    },
    nowCid: {
      type: String,
      default: ''
    }
  },
  components: {
    Popup,
    Scroll
  },
  data () {
    return {
      showFont: false,
      fontIndex: 0,
      directorySort: true,
      data: [],
      show: false
    }
  },
  methods: {
    close () {
      if (this.directoryList.length) {
        this.$emit('closeDirectory')
      } else {
        Toast.fail('暂无目录')
      }
    },
    changePage (page) {
      this.$emit('changePage', page)
      this.show = false
    }
  },
  watch: {
    directorySort () {
      this.$emit('directorySortMethod')
    },
    showDirectory (newval) {
      this.show = newval
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  width 100%

  .bottom
    position fixed
    justify-content space-around
    align-items center
    bottom 0
    width 750px
    height 97px
    display flex
    border-top 1px solid #EEEEEE
    padding-bottom constant(safe-area-inset-bottom)
    padding-bottom env(safe-area-inset-bottom)
    background #FFFFFF

    .wrapper
      display flex
      align-items center
      width 186px
      height 48px
      border-left 1px solid rgba(234, 234, 234, 1)

      &:first-child
        border-left none

      .icon
        width 30px
        height 28px
        margin-left 37px
        margin-right 9px
        background-size 100% 100%
        background-repeat no-repeat

        &.icon-favorites
          bg-image('~@/assets/img/book-detail/favorites-icon')

        &.icon-share
          bg-image('~@/assets/img/book-detail/share')

        &.icon-font
          bg-image('~@/assets/img/ebook-detail/font')

        &.icon-directory
          bg-image('~@/assets/img/ebook-detail/directory')

        &.active
          bg-image('~@/assets/img/book-detail/favorites-icon-active')

      .name
        width 100px
        line-height normal
        font-size 24px
        font-weight 500
        color rgba(153, 153, 153, 1)

  .font-block
    display flex
    align-items center
    width 750px
    height 138px
    border-radius 30px 30px 0 0
    background rgba(245, 245, 244, 1)

    .desc
      width 145px
      height 38px
      padding-left 20px
      margin-left 30px
      line-height 38px
      font-weight bold
      font-size 34px
      color rgba(51, 51, 51, 1)
      border-left 8px solid rgba(179, 53, 58, 1)

    .size
      width 230px
      height 54px
      margin-right 30px
      line-height 54px
      text-align center
      font-size 26px
      box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
      border-radius 10px
      background rgba(255, 255, 255, 1)
      border 1px solid rgba(179, 53, 58, 0)

      &.active
        border 1px solid rgba(179, 53, 58, 1)
        color rgba(179, 53, 58, 1)

  .directory-block
    width 680px
    height 100%

    .header
      display flex
      width 620px
      height 82px
      margin-left 30px
      border-bottom 1px solid rgba(245, 245, 244, 1)

      .header-name
        width 423px
        height 38px
        margin-top 30px
        padding-left 22px
        line-height 38px
        font-size 34px
        font-weight bold
        color rgba(51, 51, 51, 1)
        border-left 8px solid rgba(179, 53, 58, 1)

      .chapter
        display flex
        width 174px
        height 26px
        margin-top 34px

        .chapter-text
          width 146px
          height 26px
          line-height 26px
          font-weight 500
          font-size 26px
          color rgba(153, 153, 153, 1)

        .chapter-icon
          width 28px
          height 20px
          margin-top 3px
          background-size 100% 100%
          background-repeat no-repeat

          &.asc
            bg-image('~@/assets/img/ebook-detail/asc')

          &.desc
            bg-image('~@/assets/img/ebook-detail/desc')

    .scroll
      position absolute
      top 84px
      left 0
      right 0
      bottom 0
      overflow hidden

      .directory-list
        width 620px
        margin-left 30px
        .directory-item
          width 590px
          padding 30px 0 30px 30px
          line-height normal
          font-weight 500
          font-size 30px
          color rgba(51, 51, 51, 1)
          border-bottom 1px solid rgba(245, 245, 244, 1)
          .tip{
            color:grey;
            font-size:25px;
          }

          &:last-child
            border-bottom none
        .isactive{
          color:#b3353a;
        }
</style>
