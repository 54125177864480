<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="ebook-detail">
      <ebook-detail-qq-header v-if="showbottom"></ebook-detail-qq-header>
      <div class="ccshuqian" v-if="page_hastag"></div>
      <div class="cctipbox" v-if="showpull">
        <div class="tip" v-if="page_hastag">松手删除书签</div>
        <div class="tip" v-else>下拉添加书签</div>
      </div>
      <div class="chaptname" v-if="!showbottom">{{chapterName}}</div>
      <div ref="scroll" class="scroll"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
      >
        <div class="book-content text-flip-page" @click="changebottom"
            v-for="(page, p) in pages"
            :key="p"
            :class="{ active: currentPage === p }" :style="{fontSize: size+'px',lineHeight: size + 16 + 'px'}"
        >
          <div :ref="read_index == item.index?'target':''" :id="read_index == item.index?'target':''"
          :class="{'line':true,'isread':auto_play && (read_index == item.index)?true:false}"
          v-for="(item, index) in activePage" :key="index">
            {{ item.con }}
          </div>
        </div>
      </div>
      <div class="chaptno">第 {{currentPage+1}} 页</div>
      <!-- <scroll
        ref="scroll"
        class="scroll"
        :data="data"
        :pull-down-refresh="{threshold: 30, stop: 0}"
        @pullingDown="pullDownRefresh"
        :pull-up-load="true"
        @pullingUp="pullUpPush"
      >
        <div class="book-content" @click="changebottom">
          <div style="display: inline-block;" :style="{padding:'20px',fontSize: size+'px',lineHeight: size + 16 + 'px'}">
            <div :ref="read_index == index?'target':''" :id="read_index == index?'target':''" :class="{'line':true,'isread':auto_play && (read_index == index)?true:false}" v-for="(item, index) in lines" :key="index">
              {{ item }}。
            </div>
          </div>
        </div>
      </scroll> -->
      <!-- <div class="pagetip"><div class="div"><img class="img" src="~@/assets/img/activity/up.png" />向上滑动翻页</div></div> -->
      <ebook-detail-qq-bottom
        v-if="showbottom"
        :favorites-active="favoritesActive"
        @sizeAdd="sizeAdd"
        @sizeLess="sizeLess"
        @favorites="favorites"
        @share="share"
        :directory-list="directory"
        :nowCid="nowCid"
        @changePage="changePage"
        @directorySort="directorySort" @closeDirectory="closeDirectory"
      ></ebook-detail-qq-bottom>
      <overlay
        :show="showShare"
      >
        <share
          :show="showShare"
          :pic-url="resourcesCover"
          :name="resourcesName"
          :author="resourcesAuthor"
          :user-name="userName"
          :agency-guid="agencyGuid"
          :user-guid="userGuid"
          @shareClose="showShare=false"
        ></share>
      </overlay>
       <overlay :show="showSignFlag">
        <sign-overlay @close="showSignFlag = false"></sign-overlay>
      </overlay>
      <template>
        <img class="audiobtn playing" @click="showaudio=true" v-if="auto_play" round src="https://msyd.nmgcnt.com/uploads/listenicon.png">
        <template v-else>
          <van-button class="audiobtn" style="width:50px;height: 50px;" v-if="loading" loading round loading-type="spinner"></van-button>
          <img class="audiobtn" v-else @click="tostartplay" round src="https://msyd.nmgcnt.com/uploads/listenicon.png">
        </template>
      <!-- <aplayer :audio="audio" @pause="tostop" @timeupdate="timechange" @abort="myabort" loop="none" @ended="onEnded" ref="audioElement" :options="playerOptions"></aplayer> -->
      <audio :src="audio.url" @pause="tostop" @timeupdate="timechange" @ended="onEnded" ref="audioElement" :options="playerOptions"></audio>
    </template>
      <player-detail-new :showaudio="showaudio" :currentSong="audio" :maxSliderValue="media_duration" :currentTime="sliderValue" :auto_play="auto_play" v-on:toloadAudio="toloadAudio" v-on:tostop="tostop" v-on:changeslider="changeslider" v-on:changespeed="changespeed" v-on:closeaudio="closeaudio" :favorites="favoritesActive" @favorites="favorites" :offtime="offtime" v-on:setofftime="setofftime" v-on:next="next" v-on:prev="prev" :directoryList="directory" v-on:closeDirectory="closeDirectory" :loading="loading"></player-detail-new>
      <Ebook-Detail-Qq-Mulu :directory-list="directory" :showDirectory="showDirectory" :nowCid="nowCid"
        v-on:changePage="changePage" v-on:directorySortMethod="directorySort" v-on:closeDirectory="closeDirectory"></Ebook-Detail-Qq-Mulu>
    </div>
  </transition>
</template>

<script>
// import Scroll from '@/components/scroll/scroll'
import EbookDetailQqHeader from './components/Header'
// import EbookDetailQqContent from './components/Content'
import EbookDetailQqBottom from './components/Bottom'
import { Toast, Overlay } from 'vant'
import { getResourcesDescQQ, getResourcesDirectoryQQ, setCUserLog, makeaudioLong, makeaudioShort, hastag, deltag, addtag } from '@/api/Resources'
import { mapGetters } from 'vuex'
import { appInit } from '@/utils/init'
import { getCUserFavoritesFlag, setCUserFavorites } from '@/api/Cuser'
import Share from '../book-detail/components/Share'
import { collect } from '@/api/Collects'
import { setGameSec } from '@/api/Questions'
import { signIn21Day } from '@/api/SignIn'
import { getDate } from '@/utils/date'
import SignOverlay from '../common/SignOverlay'
import PlayerDetailNew from './components/PlayerDetailNew.vue'
import EbookDetailQqMulu from './components/mulu.vue'
export default {
  name: 'EbookDetailQq',
  components: {
    EbookDetailQqHeader,
    PlayerDetailNew,
    // Scroll,
    // EbookDetailQqContent,
    EbookDetailQqBottom,
    EbookDetailQqMulu,
    Overlay,
    Share,
    SignOverlay
  },
  computed: {
    ...mapGetters([
      'userGuid',
      'userName',
      'agencyGuid',
      'userCityName'
    ]),
    activePage () {
      return this.pages[this.currentPage]
    }
  },
  mounted () {
    this.screenHeight = window.innerHeight // 获取屏幕高度
    this.maxPageHeight = this.screenHeight - 180 // 假设页面高度占屏幕高度的80%
    const screenWidth = window.innerWidth - 90
    // 估算每行文本的高度（这里简化处理，直接使用字体大小和行高
    const estimatedLineHeight = this.fontSize * 2
    const wordnum = Math.ceil(screenWidth / (this.fontSize * 1.2))
    this.linewordnum = wordnum
    // 根据页面高度和每行文本高度计算每页可以显示的行数
    const linesPerPage = Math.floor(this.maxPageHeight / estimatedLineHeight)
    // console.log('linesPerPage', linesPerPage)
    console.log('this.maxPageHeight', this.maxPageHeight)

    const wordnumtotal = wordnum * linesPerPage
    this.wordnumtotal = wordnumtotal

    // console.log('this.wordnum', wordnum)
    // console.log('this.linesPerPage', linesPerPage)
    //
    this.audioElement = this.$refs.audioElement
    this.registerMediaSession()
    document.title = this.$route.params.resources_name
    this._txtInit()
    // 集字
    this.gameTimer = setInterval(() => {
      if (!this.$dbfToast.$data.show) {
        collect({
          c_user_guid: this.userGuid,
          agency_guid: this.agencyGuid
        }).then(res => {
          if (res.code === 200) {
            this.$dbfToast.showToast()
          }
        })
      }
    }, 5000)
    // 答题
    this.gameTimer2 = setInterval(() => {
      if (!this.$questionPopup.$data.show) {
        setGameSec({
          c_user_guid: this.userGuid,
          agency_guid: this.agencyGuid
        }).then(res => {
          if (res.code === 200) {
            if (res.data.activity) {
              localStorage.setItem('activity', JSON.stringify(res.data.activity))
            }
            if (res.data && res.data.ok && res.data.ok === 1) {
              this.$questionPopup.showPopup()
            }
          } else {
            clearTimeout(this.gameTimer2)
          }
        })
      }
    }, 12000)
  },
  beforeDestroy () {
    clearInterval(this.gameTimer)
    clearInterval(this.gameTimer2)
    clearInterval(this.getaints)
    clearInterval(this.loadins)
    this.playtime = 0
    this.newlinearr = []
  },
  data () {
    return {
      prevCid: '',
      nowCid: '',
      nextCid: '',
      content: '',
      favoritesActive: false,
      data: [],
      pullUpFlag: false,
      size: 19, // 字体大小
      directory: [],
      showShare: false,
      resourcesCover: '',
      resourcesName: '',
      resourcesAuthor: '',
      showSignFlag: false,
      signPage: 1, // 活动签到
      lines: [],
      ints: null,
      read_index: 0,
      auto_play: false,
      audioUrl: '',
      audioElement: null,
      contentform: {},
      content_new_arr: [],
      getaints: null,
      start_get_a: false,
      loading: null,
      loadins: null,
      playing: false,
      audio: {
        name: '',
        artist: '',
        url: '',
        cover: '', // prettier-ignore
        lrc: '',
        resources_desc: ''
      },
      playerOptions: {
        narrow: true,
        autoplay: false,
        showLrc: true,
        mutex: true,
        theme: '#F57F17',
        loop: 'all',
        order: 'list',
        preload: 'auto',
        listFolded: true,
        listMaxHeight: '100%'
      },
      sort: 0,
      content_arr: [],
      lastline: 0,
      isend: true,
      separators: /[。]/,
      // separators: '。',
      newlinearr: [],
      playtime: 0,
      showaudio: false,
      media_duration: 0,
      sliderValue: 0,
      playbackRate: 1,
      offtime: 0,
      offtimer: null,
      showDirectory: false,
      showbottom: false,
      //
      startX: 0,
      endX: 0,
      threshold: 100, // 滑动阈值
      currentPage: 0, // 当前页面索引
      screenHeight: 0, // 屏幕高度
      lineHeight: 1.5, // 假设的行高，具体值可能需要根据字体样式调整
      fontSize: 16, // 假设的字体大小，具体值可能需要根据字体样式调整
      maxPageHeight: 0, // 每个页面的最大高度
      wordnumtotal: 0,
      linewordnum: 0,
      newLines: [],
      pages: [],
      mediaFirstLine: 0,
      audiopages: [],
      curr_audiopage: 0,
      chapterName: '',
      chapterOrder: 1,
      startY: 0,
      page_hastag: 0,
      showpull: false

    }
  },
  methods: {
    getpages () {
      // console.log('this.lines', this.lines)
      const pages = []
      let cuTextArr = []
      let mytext = ''
      for (let i = 0; i < this.lines.length; i++) {
        let item = { index: i, con: this.lines[i] + '。' }
        cuTextArr.push(item)
        mytext += item.con
        let textcount = mytext.length
        let nextNum = 0
        if (this.lines[i + 1]) {
          nextNum = this.lines[i + 1].length
        } else {
          pages.push(cuTextArr)
          cuTextArr = []
          mytext = ''
        }
        if (textcount < this.wordnumtotal) {
          if (this.wordnumtotal - textcount < nextNum) {
            pages.push(cuTextArr)
            cuTextArr = []
            mytext = ''
          }
        } else {
          pages.push(cuTextArr)
          cuTextArr = []
          mytext = ''
        }
      }
      // for (let i = 0; i < textLength; i += pageSize) {
      //   const end = Math.min(i + pageSize, textLength)
      //   pages.push(this.content.substring(i, end))
      // }
      // console.log('pages', pages)
      this.pages = pages
    },
    getaudiopages () {
      let conArrList = []
      let item = {
        lastcontent: '',
        lastcontentarr: [],
        content: '',
        contentarr: [],
        length: 0
      }
      for (let i = 0; i < this.lines.length; i++) {
        if (item.content.length + (this.lines[i] + '，').length < 300) {
          item.lastcontent = item.content
          item.lastcontentarr = item.contentarr
          item.content = item.content += this.lines[i] + '，'
          item.contentarr.push({
            con: this.lines[i],
            index: i
          })

          if (item.content.length === 300) {
            conArrList.push({
              content: item.content,
              contentarr: item.contentarr,
              index: i - item.lastcontentarr.length + 1,
              length: item.content.length,
              lastindex: i
            })
            item.lastcontent = ''
            item.content = ''
            item.lastcontentarr = []
            item.contentarr = []
          }
          if (item.content.length > 300) {
            i = i - 1
            conArrList.push({
              content: item.lastcontent,
              contentarr: item.lastcontentarr,
              index: i - item.lastcontentarr.length + 1,
              length: item.lastcontent.length,
              lastindex: i
            })

            item.lastcontent = ''
            item.content = ''
            item.lastcontentarr = []
            item.contentarr = []
          }
          if (i >= this.lines.length - 1) {
            conArrList.push({
              content: item.content,
              contentarr: item.contentarr,
              index: i - item.lastcontentarr.length + 1,
              length: item.content.length,
              lastindex: i
            })
            item.lastcontent = ''
            item.content = ''
            item.lastcontentarr = []
            item.contentarr = []
            break
          }
        } else {
          i--
          conArrList.push({
            content: item.content,
            contentarr: item.contentarr,
            index: i - item.lastcontentarr.length + 1,
            length: item.content.length,
            lastindex: i
          })
          item.lastcontent = ''
          item.content = ''
          item.lastcontentarr = []
          item.contentarr = []
        }
      }
      // console.log('conArrList', conArrList)
      this.audiopages = conArrList
    },
    handleTouchStart (e) {
      this.startY = e.touches[0].clientY
      if (this.auto_play) {
        return false
      }
      this.startX = e.touches[0].clientX
    },
    handleTouchMove (e) {
      // 这里可以添加滑动时的逻辑，比如页面跟随滑动等
      // 当向下拉时触发
      const currentY = e.touches[0].clientY
      if (currentY > this.startY && this.$el.scrollTop === 0) {
        e.preventDefault()
        const distance = currentY - this.startY
        if (distance > 50) { // 设置一个阈值，当拉动超过这个距离时开始刷新
          // console.log('下拉--')
          this.showpull = true
        }
      }
    },
    handleTouchEnd (e) {
      if (this.showpull) {
        this.showpull = false
        if (this.page_hastag) {
          this._deltag()
        } else {
          this._addtag()
        }
        return false
      }
      if (this.auto_play) {
        return false
      }
      this.endX = e.changedTouches[0].clientX
      if (this.endX - this.startX === 0) {
        return false
      }
      // console.log('this.endX', this.endX)
      // console.log('this.startX', this.startX)
      // 判断滑动方向
      let swipeDirection = ''
      if (Math.floor(this.endX) - Math.floor(this.startX) < 0 && Math.floor(this.endX) - Math.floor(this.startX) < -20) {
        swipeDirection = 'right'
      }
      if (Math.floor(this.endX) - Math.floor(this.startX) > 0 && Math.floor(this.endX) - Math.floor(this.startX) > 20) {
        swipeDirection = 'left'
      }

      // 更新页面
      if (swipeDirection === 'right') {
        this.showbottom = false
        if (this.currentPage >= this.pages.length - 1) {
          // console.log(1111)
          this.pullUpFlag = true
          this.pullUpPush()
        } else {
          this.currentPage++
          this.signPage++
          this._setCUserLog()
          this._hastag()
        }
      } else if (swipeDirection === 'left') {
        this.showbottom = false
        if (this.currentPage > 0) {
          this.currentPage--
          this._setCUserLog()
          this._hastag()
        } else {
          this.pullDownRefresh()
        }
      }
    },
    //
    changebottom () {
      this.showbottom = !this.showbottom
    },
    closeDirectory () {
      this.showDirectory = !this.showDirectory
    },
    tostartplay () {
      this.getCurrPageAudio()
      this.showaudio = true
      this.toloadAudio()
    },
    getCurrPageAudio () {
      if (this.currentPage > 0) {
        let currpage = this.pages[this.currentPage]
        let startindex = 0
        // let lastindex = 0
        if (currpage[0]) {
          startindex = currpage[0].index
        }
        // if (currpage[currpage.length - 1]) {
        //   lastindex = currpage[currpage.length - 1].index
        // }

        for (let i = 0; i < this.audiopages.length; i++) {
          let item = this.audiopages[i]
          if (item.index >= startindex) {
            this.sort = i
            this.isend = true
            this.read_index = item.index
            this.lastline = item.index
            break
          }
        }
        // console.log('currpage', currpage)
        // console.log('this.sort', this.sort)
        // console.log('this.read_index', this.read_index)
        return true
      } else {
        return true
      }
    },
    next () {
      this.onEnded()
    },
    setofftime (value) {
      if (value.value !== 0) {
        this.offtime = value.value
        clearTimeout(this.offtimer)
        if (value.value > 0) {
          this.offtimer = setTimeout(() => {
            this.tostop()
          }, value.value * 1000)
        }
      }
    },
    closeaudio () {
      this.showaudio = false
    },
    changespeed (value) {
      this.playbackRate = value
    },
    changeslider (value) {
      // const { media } = this.$refs.audioElement
      const media = this.$refs.audioElement
      // const media = this.$refs.audioElement
      media.currentTime = value
      this.auto_play = true
      this.audioElement.play()
      // media.seek(value)
    },
    myabort () {
    },
    timechange (e, e1) {
      // const { media } = this.$refs.audioElement
      const media = this.$refs.audioElement
      if (media) {
        let currentline = this.newlinearr[this.read_index]
        this.sliderValue = media.currentTime
        this.media_duration = media.duration
        // console.log('media.currentTime', media.currentTime)
        // if (media.duration - 0.01 <= media.currentTime) {
        //   // this.onEndednew()
        //   // if (this.lines.length - 1 > 0 && this.lines.length - 1 <= this.read_index) {
        //   //   this.one_end()
        //   // }
        // }
        if (currentline && media.currentTime >= currentline.timeend) {
          this._readline()
        }
      }
    },
    one_end () {
      this.tostop()
      this.isend = true
      clearInterval(this.getaints)
      clearInterval(this.loadins)
      this.pullUpFlag = true
      console.log('end-ok--')
      if (this.currentPage >= this.pages.length - 1) {
        this.pullUpPushNew()
      } else {
        console.log('翻页--')
        // this.currentPage++
      }
    },
    registerMediaSession () {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.setActionHandler('play', () => {
          this.audioElement.play()
        })

        navigator.mediaSession.setActionHandler('pause', () => {
          this.audioElement.pause()
        })

        navigator.mediaSession.setActionHandler('stop', () => {
          this.audioElement.pause()
        })

        navigator.mediaSession.setActionHandler('seekbackward', () => {
          this.audioElement.seek(this.audioElement.media.currentTime - 10)
        })

        navigator.mediaSession.setActionHandler('seekforward', () => {
          this.audioElement.seek(this.audioElement.media.currentTime + 10)
        })

        navigator.mediaSession.setActionHandler('seekto', (event) => {
          this.audioElement.seek(event.seekTime)
        })
      }
    },
    onEnded () {
      console.log('end---')
      // const { media } = this.$refs.audioElement
      const media = this.$refs.audioElement
      this.playtime += media.duration
      this.tostop()
      this.isend = true

      if (this.offtime === 'now-over') {
        this.offtime = 0
        return false
      }
      if (this.lines.length - 1 > 0 && this.lines.length - 1 <= this.read_index) {
        console.log('end-ok2--')
        this.one_end()
      } else {
        console.log('continue--')
        this.sort++
        this.curr_audiopage++
        this.toloadAudio()
      }
    },
    prev () {
      console.log('prev---')
      // const { media } = this.$refs.audioElement
      const media = this.$refs.audioElement
      this.playtime += media.duration
      this.tostop()
      this.isend = true
      if (this.sort > 0) {
        this.sort--
        this.curr_audiopage--
        this.toloadAudio()
      } else {
        Toast.fail('没有了')
      }
    },
    onEndednew () {
      console.log('endnew---')
      // const { media } = this.$refs.audioElement
      const media = this.$refs.audioElement
      this.playtime += media.duration
      this.tostop()
      this.isend = true

      if (this.lines.length - 1 > 0 && this.lines.length - 1 <= this.read_index) {
        console.log('end-ok2--')
        this.one_end()
      } else {
        console.log('continue--')
        this.sort++
        this.curr_audiopage++
        this.toloadAudio()
      }
    },
    toloadAudio () {
      this.start_get_a = true
      this.audio.name = this.contentform.resources_name
      this.audio.artist = this.contentform.resources_author
      this.audio.cover = this.contentform.resources_cover
      this.audio.resources_desc = this.contentform.resources_desc

      if (this.isend) {
        console.log('kaishi---')
        this.loading = true
        let contentarr = []
        var n = 0
        if (!this.lines[this.lastline + n]) {
          this.one_end()
        }
        contentarr = this.audiopages[this.sort].contentarr

        if (contentarr.length < 1) {
          this.one_end()
        }

        // console.log('contentarr--', contentarr)
        let newlinearr = []
        let strlen = 0
        let textarr = []
        contentarr.forEach((line, index) => {
          // if (index === 0) {
          //   this.read_index = line.index
          // }
          let newline = ''
          if (this.lines[line.index]) {
            textarr.push(line.con)
            newline = line.con.replace(/[`:_.~!@#$%^&*() + =<>?"{}|, / ;' \\ [ \] ·~！@#￥%……&*（）—— + ={}|《》？：“”【】、；‘’，。、]/g,
              '')
            // newline = line
            newline = newline.replace(/\n/g, '')
            newline = newline.replace(' ', '')
            newline = newline.replace(/\r/g, '')
            newlinearr[line.index] = {
              index: line.index,
              length: newline.length,
              content: this.lines[line.index],
              timestart: strlen,
              timeend: strlen + newline.length * 0.21
            }
            strlen = strlen + newline.length * 0.21
          }
        })
        // console.log('newlinearr--', newlinearr)
        if (newlinearr.length < 1) {
          console.log('empty')
        }
        this.newlinearr = newlinearr

        this.read_index = this.lastline
        this.lastline = contentarr[contentarr.length - 1].index + 1
        // this.mediaFirstLine =
        this.contentform.content = textarr.join('。')
        if (this.contentform.content.length < 1 || strlen < 1) {
          this.one_end()
        }
      }
      this.contentform.sort = this.sort
      this._makeaudioShort()
    },
    tostop () {
      if (this.auto_play) {
        this.auto_play = false
        this.audioElement.pause()
      }
    },
    toplay () {
      if (!this.auto_play) {
        if (this.audioElement) {
          this.auto_play = true
          // const { media } = this.$refs.audioElement
          const media = this.$refs.audioElement
          this.media_duration = media.duration
          this.start_read()
          media.playbackRate = this.playbackRate
          this.audioElement.play()
        }
      }
    },
    readyplay () {
      console.log('ready---')
    },
    _makeaudioShort () {
      let myform = {}
      myform.bid = this.contentform.bid
      myform.cid = this.contentform.cid
      myform.content = this.contentform.content
      myform.next_cid = this.contentform.next_cid
      myform.prev_cid = this.contentform.prev_cid
      myform.resources_guid = this.contentform.resources_guid
      myform.sort = this.contentform.sort
      myform.resources_name = this.contentform.resources_name
      // console.log(this.contentform)
      // console.log(myform)
      // if (myform) {
      //   return false
      // }

      makeaudioShort(myform).then(res => {
        if (res.code === 200 && res.data && res.data.media_file) {
          clearInterval(this.getaints)
          this.start_get_a = false
          this.audioUrl = res.data.media_file
          this.audio.url = res.data.media_file
          this.loading = false
          this.isend = false
          this.loadins = setTimeout(() => {
            this.toplay()
          }, 10)
        } else {
          this.loading = true
          if (this.start_get_a) {
            this.getaints = setInterval(() => {
              this._makeaudioShort()
            }, 1000)
          }
        }
      })
    },
    _makeaudioLong () {
      this.loading = true
      makeaudioLong(this.contentform).then(res => {
        // console.log(res)
        if (res.code === 200 && res.data && res.data.media_file) {
          this.start_get_a = false
          this.audioUrl = res.data.media_file
          this.audio.url = this.audioUrl
          clearInterval(this.getaints)
          this.loading = false
          this.loadins = setTimeout(() => {
            this.toplay()
          }, 50)
        } else {
          this.loading = true
        }
      })
    },
    start_read () {
      if (this.auto_play) {
        // const target = this.$refs.target
        // if (target[0]) {
        //   target[0].scrollIntoView({ behavior: 'smooth' }) // 'smooth' 参数使得滚动动作平滑执行
        //   window.scrollTo({ behavior: 'smooth', top: target[0].offsetTop })
        // }
        // document.getElementById('target').scrollIntoView({ behavior: 'smooth' })
        console.log('to-load---')
        this.toloadAudio()
      }
    },
    _readline () {
      if (!this.auto_play) {
        return false
      }
      if (this.auto_play) {
        if (this.read_index < this.lines.length - 1) {
          this.read_index++
          // const { media } = this.$refs.audioElement
          // const media = this.$refs.audioElement
          console.log(this.read_index)
          // const target = this.$refs.target
          // if (target[0]) {
          //   target[0].scrollIntoView({ behavior: 'smooth' }) // 'smooth' 参数使得滚动动作平滑执行
          // }

          const currPage = this.pages[this.currentPage]
          if (currPage[currPage.length - 1].index < this.read_index) {
            this.currentPage++
            this.signPage++
            console.log('this.currentPage--', this.currentPage)
            this._setCUserLog()
          }
          if (this.lines.length > 0 && this.lines.length <= this.read_index) {
            this.one_end()
          }
        }
      }
    },
    _startread () {
      clearInterval(this.getaints)
      clearInterval(this.loadins)
      this.lastline = 0
      this.read_index = 0
      this.lines = this.content.split(this.separators)
      if (this.lines[this.lines.length - 1] === '') {
        this.lines.pop()
      }
      this.getpages()
      this.getaudiopages()
      this.isend = true
      // const target = this.$refs.target
      // if (target[0]) {
      //   target[0].scrollIntoView({ behavior: 'smooth' }) // 'smooth' 参数使得滚动动作平滑执行
      //   window.scrollTo({ behavior: 'smooth', top: target[0].offsetTop })
      // }
      // document.getElementById('target').scrollIntoView({ behavior: 'smooth' })
      console.log('to-load---')
      this.toloadAudio()
    },
    _startread2 () {
      clearInterval(this.getaints)
      clearInterval(this.loadins)
      this.lastline = 0
      this.read_index = 0
      this.lines = this.content.split(this.separators)
      if (this.lines[this.lines.length - 1] === '') {
        this.lines.pop()
      }
      this.getpages()
      this.getaudiopages()
    },
    _txtInit () {
      let cid = ''
      if (this.$route.query.page) {
        cid = this.$route.query.page
      }
      if (this.auto_play) {
        this.tostop()
        this.isend = true
        clearInterval(this.getaints)
        clearInterval(this.loadins)
        this.pullUpFlag = true
        console.log('end-ok--')
        this._getResourcesDescQQ(cid, 1)
      } else {
        this._getResourcesDescQQ(cid)
      }

      getCUserFavoritesFlag({
        resources_guid: this.$route.params.resources_guid,
        c_user_guid: this.userGuid
      }).then(res => {
        if (res.code === 200) {
          this.favoritesActive = res.data
        }
      })
      getResourcesDirectoryQQ({
        resources_guid: this.$route.params.resources_guid,
        c_user_guid: this.userGuid
      }).then(res => {
        if (res.code === 200) {
          this.directory = res.data
        }
      })
    },
    _getResourcesDescQQ (cid, ctype = 0) {
      let _this = this
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      let data = {
        resources_guid: this.$route.params.resources_guid,
        c_user_guid: this.userGuid
      }
      if (cid) {
        data.cid = cid
      }
      getResourcesDescQQ(data).then(res => {
        if (res.code === 200) {
          console.log('reload-text---')
          _this.showbottom = false
          _this.read_index = 0
          _this.sort = 0
          _this.lastline = 0
          _this.playtime = 0
          _this.prevCid = res.data.prev_cid
          _this.nowCid = res.data.cid
          _this.nextCid = res.data.next_cid
          _this.content = res.data.content
          // _this.content = res.data.content.replace(/(\r\n|\n|\r)/gm, '')
          _this.resourcesCover = res.data.resources_cover
          _this.resourcesName = res.data.resources_name
          _this.resourcesAuthor = res.data.resources_author
          _this.contentform = res.data
          _this.chapterName = res.data.chapterName
          _this.chapterOrder = res.data.chapterOrder
          _this.contentform.sort = 0
          if (res.data.curr_page > -1) {
            _this.currentPage = res.data.curr_page
          } else {
            _this.currentPage = 0
          }
          _this.newlinearr = []
          _this._setCUserLog()
          _this._hastag()
          _this.refresh()
          Toast.clear()
          if (ctype === 1) {
            _this._startread()
          } else {
            _this._startread2()
          }
        } else if (res.code === 401) {
          appInit(this.agencyGuid, this.$route.path)
        } else if (res.code === 402) {
          Toast.clear()
          _this.$router.push({
            name: 'Login',
            params: {
              link: this.$route.path
            }
          })
        } else {
          Toast.fail(res.msg)
        }
      })
    },
    refresh () {
      // setTimeout(() => {
      //   // this.$refs.scroll.refresh()
      // }, 100)
    },
    pullDownRefresh () {
      if (this.prevCid === '0') {
        Toast.fail('没有上一页了')
        return false
      }

      if (this.auto_play) {
        this.tostop()
        this.isend = true
        clearInterval(this.getaints)
        clearInterval(this.loadins)
        this.pullUpFlag = true
        console.log('end-ok--')
        this._getResourcesDescQQ(this.prevCid, 1)
      } else {
        this._getResourcesDescQQ(this.prevCid)
      }
    },
    pullUpPush () {
      if (this.nextCid === '0') {
        Toast.fail('没有下一页了')
        return false
      }
      if (this.pullUpFlag) {
        this.content = ''
        this.$refs.scroll.scrollTo(-160, 0)

        if (this.auto_play) {
          this.tostop()
          this.isend = true
          clearInterval(this.getaints)
          clearInterval(this.loadins)
          this.pullUpFlag = true
          console.log('end-ok--')
          this._getResourcesDescQQ(this.nextCid, 1)
        } else {
          this._getResourcesDescQQ(this.nextCid)
        }
        this.pullUpFlag = false
        this.signPage++
      } else {
        this.pullUpFlag = true
      }
    },
    pullUpPushNew () {
      if (this.nextCid === '0') {
        Toast.fail('没有下一页了')
        return false
      }
      if (this.pullUpFlag) {
        this.content = ''
        clearInterval(this.getaints)
        clearInterval(this.loadins)
        this._getResourcesDescQQ(this.nextCid, 1)
        this.pullUpFlag = false
        this.signPage++
      } else {
        this.pullUpFlag = true
      }
    },
    sizeAdd () {
      if (this.size >= 36) {
        Toast.fail('不能再大了')
        return false
      }
      this.size += 2
      this.refresh()
    },
    sizeLess () {
      if (this.size <= 16) {
        Toast.fail('不能再小了')
        return false
      }
      this.size -= 2
      this.refresh()
    },
    favorites (flag) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      setCUserFavorites({
        c_user_guid: this.userGuid,
        favorites_obj: this.$route.params.resources_guid,
        favorites_type: 1,
        favorites_status: flag ? -1 : 1
      }).then(res => {
        if (res.code === 200) {
          this.favoritesActive = !flag
          Toast.clear()
        }
      })
    },
    share () {
      this.showShare = true
    },
    changePage (page) {
      this.content = ''
      this.$refs.scroll.scrollTo(-84, 0)
      if (this.auto_play) {
        this.tostop()
        this.isend = true
        clearInterval(this.getaints)
        clearInterval(this.loadins)
        this.pullUpFlag = true
        console.log('end-ok--')
        this._getResourcesDescQQ(page, 1)
      } else {
        this._getResourcesDescQQ(page)
      }
    },
    directorySort () {
      this.directory.reverse()
    },
    _setCUserLog () {
      setCUserLog({
        resources_guid: this.$route.params.resources_guid,
        c_user_guid: this.userGuid,
        agency_guid: this.agencyGuid,
        c_user_location: this.userCityName,
        resources_ext: this.nowCid,
        resources_type: 5,
        curr_page: this.currentPage
      })
    },
    _hastag () {
      hastag({
        resources_guid: this.$route.params.resources_guid,
        cuser_guid: this.userGuid,
        resources_cid: this.nowCid,
        curr_page: this.currentPage
      }).then(res => {
        if (res.code === 200) {
          this.page_hastag = res.data.hastag
        }
      })
    },
    _addtag () {
      addtag({
        resources_guid: this.$route.params.resources_guid,
        cuser_guid: this.userGuid,
        resources_cid: this.nowCid,
        curr_page: this.currentPage
      }).then(res => {
        if (res.code === 200) {
          this._hastag()
        }
      })
    },
    _deltag () {
      deltag({
        resources_guid: this.$route.params.resources_guid,
        cuser_guid: this.userGuid,
        resources_cid: this.nowCid,
        curr_page: this.currentPage
      }).then(res => {
        if (res.code === 200) {
          this._hastag()
        }
      })
    }
  },
  watch: {
    playbackRate (newval) {
      this.$refs.audioElement.playbackRate = newval
    },
    // nowCid () {
    //   setCUserLog({
    //     resources_guid: this.$route.params.resources_guid,
    //     c_user_guid: this.userGuid,
    //     agency_guid: this.agencyGuid,
    //     c_user_location: this.userCityName,
    //     resources_ext: this.nowCid,
    //     resources_type: 5,
    //     curr_page: this.currentPage,
    //     curr_audio_sort: this.curr_audiopage,
    //     audio_time: this.currentTime,
    //     readline: this.read_index
    //   })
    // },
    signPage (page) {
      if (page >= 3) {
        console.log('看书签到')
        signIn21Day({
          sign_in_user: this.userGuid,
          sign_in_agency: this.agencyGuid,
          sign_in_time: getDate(),
          sign_in_from: 1
        }).then(res => {
          const { code } = res
          if (code === 200) {
            this.signPage = 1
            this.showSignFlag = true
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.ebook-detail
  position absolute
  z-index 10000
  top 0
  left 0
  right 0
  bottom 0
  background #F6F6F6;
  background-repeat:no-repeat;
  background-size:100% 100%;
  .ccshuqian{
    width:47px;
    height:90px;
    background-image:url('~@/assets/img/shuqian.png');
    background-repeat:no-repeat;
    background-size:100% 100%;
    position: absolute;
    top: 0;
    right: 70px;
  }
  .cctipbox{
    width:100%;
    height:200px;
    z-index 1
    position static
    top:0;
    left:0;
    .tip{
      text-align:center;
      padding:50px;
    }
  }
  .chaptname{
    font-size:25px;
    height:84px;
    line-height:84px;
    padding-left:35px;
    padding-top:10px;
    box-sizing: border-box;
    color:#5A5A5A;
  }
  .chaptno{
    position:absolute;
    bottom:40px;
    right:35px;
    font-size:25px;
    color:#5A5A5A;
    background-color:initial
  }
  .pagetip{
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color:#ffffff;
    height:40px;
    line-height:40px;
    .div{
      height:40px;
      justify-content:center;
      padding: 3px 10px;
      display:inline-flex;
      .img{
        height:40px;
      }
    }
  }
  .scroll
    position static
    top 84px
    left 0
    right 0
    bottom 30px
    bottom calc(constant(safe-area-inset-bottom) + 30px)
    bottom calc(env(safe-area-inset-bottom) + 30px)
    overflow hidden
    .book-content{
      box-sizing:border-box;
      color #080808
      white-space:break-spaces;
      .isread{
        color: #82550E;
      }
    }
  .audiobtn{
    position:absolute;
    right:40px;
    bottom:60px;
    padding:0;
    width:100px;
    height:100px;
  }
  .line{
    display:unset;
  }

  .playing {
      animation: rotate-animation 2s linear infinite; /* 无限次重复播放动画 */
  }
  @keyframes rotate-animation {
      0% { transform: rotate(0deg); } /* 初始状态 */
      100% { transform: rotate(360deg); } /* 结束状态 */
  }

  .text-flip-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh; /* 或者你需要的任何高度 */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-flip-page {
    position: absolute;
    width: 100%;
    height: 100%;
    padding:0px 20px 20px 35px;
    box-sizing: border-box;
    transition: transform 0.3s ease;
    transform: translateX(100%); /* 初始时所有页面都在容器外部 */
    opacity: 0; /* 初始时所有页面都是透明的 */
  }

  .text-flip-page.active {
    transform: translateX(0); /* 当前页面显示在容器内 */
    opacity: 1; /* 当前页面不透明 */
  }
</style>
