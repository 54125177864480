<template>
  <transition :appear="true" name="drop">
    <div class="player" v-show="showaudio">
      <div class="bg-blur" :style="{backgroundImage: 'url(' + currentSong.cover + ')'}"></div>
      <div class="wrapper">
        <div class="header">
          <div class="header-back">
            <div class="icon-back-block" @click="back">
              <div class="icon-back"></div>
            </div>
            <div class="header-content">{{ currentSong.name }}</div>
          </div>
          <div class="header-author">{{ currentSong.artist }}</div>
        </div>
        <div class="content">
          <swiper class="swiper" :options="swiperOptions">
            <swiper-slide class="swiper-slide">
              <div class="cover-block">
                <div class="cover">
                  <img class="cover-img" :src="currentSong.cover"/>
                </div>
                <div class="cd"></div>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <scroll ref="scroll" class="scroll" :data="data">
                <div>
                  <div class="desc">
                    <div class="desc-title">{{ currentSong.name }}
                    </div>
                    <div class="desc-author">作者：{{ currentSong.artist }}
                    </div>
                    <div class="desc-anchor">主播：AI女声
                    </div>
                    <div class="desc-content">
                      简介：{{ currentSong.resources_desc }}
                    </div>
                  </div>
                </div>
              </scroll>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="bottom">
          <div class="icon-list">
            <div class="icon-block" @click="userFavorites">
              <div class="icon-block-icon icon-favorites" :class="{active: favorites}"></div>
              <div class="icon-block-content">收藏</div>
            </div>
            <div class="icon-block" @click="timedOffPopup = true">
              <div class="icon-block-icon icon-timing"></div>
              <div class="icon-block-content">定时</div>
            </div>
            <div class="icon-block" @click="speedPopup = true">
              <div class="icon-block-icon icon-speed">
                {{ speedOption[speedOptionIndex] }}
              </div>
              <div class="icon-block-content">倍速</div>
            </div>
            <div class="icon-block" @click="listPopupClick">
              <div class="icon-block-icon icon-lst"></div>
              <div class="icon-block-content">目录</div>
            </div>
          </div>
          <div class="slider">
            <slider v-model="sliderValue"
                    :max="maxSliderValue"
                    @drag-start="sliderStart"
                    @change="sliderEnd"
                    active-color="rgba(179, 53, 58, 1)"
                    inactive-color="rgba(255, 255, 255, .4)">
              <template #button>
                <div class="custom-button">
                </div>
              </template>
            </slider>
          </div>
          <div class="slider-desc">
            <div class="now-slider">{{ format(sliderValue) }}</div>
            <div class="total-slider">{{ format(maxSliderValue) }}</div>
          </div>
          <div class="operating">
            <div class="icon-retreat" @click="changeSliderValue(-15)"></div>
            <div class="icon-prev" @click="prev"></div>
            <van-button class="icon-play" v-if="loading" loading round loading-type="spinner"></van-button>
            <div class="icon-play" v-else :class="{active: auto_play}" @click="playerPause"></div>
            <div class="icon-next" @click="next"></div>
            <div class="icon-forward" @click="changeSliderValue(15)"></div>
          </div>
          <div class="icon-pause"></div>
        </div>
      </div>
      <popup
        v-model="speedPopup"
        round
        position="bottom"
        class="speed-popup"
        safe-area-inset-bottom
      >
        <ul class="speed-block">
          <li
            class="speed-option"
            v-for="(item, index) in speedOption"
            :key="index"
            @click="speedOptionIndex = index"
          >
            <div class="speed" :class="{active: speedOptionIndex===index}">{{ item }}</div>
            <div v-show="speedOptionIndex===index" class="speed-active"></div>
          </li>
        </ul>
        <div class="speed-cancel" @click="speedPopup = false">取消</div>
      </popup>
      <popup
        v-model="timedOffPopup"
        round
        position="bottom"
        class="speed-popup"
        safe-area-inset-bottom
      >
        <ul class="speed-block">
          <li
            class="speed-option"
            v-for="(item, index) in timedOffOption"
            :key="index"
            @click="setofftime(index)"
          >
            <div v-if="item.value === 'now-over'" class="speed" :class="{active: timeOffIndex===index}">
              {{ item.name }}（{{ format(maxSliderValue - sliderValue) }}）
            </div>
            <div v-else class="speed" :class="{active: timeOffIndex===index}">
              {{ item.name }}
            </div>
            <div v-show="timeOffIndex===index" class="speed-active"></div>
          </li>
        </ul>
        <div class="speed-cancel" @click="timedOffPopup = false">取消</div>
      </popup>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Slider, Popup, Toast } from 'vant'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Scroll from '@/components/scroll/scroll'
export default {
  name: 'PlayerDetailNew',
  components: {
    Slider,
    Swiper,
    SwiperSlide,
    Scroll,
    Popup
  },
  props: ['currentSong', 'showaudio', 'maxSliderValue', 'auto_play', 'currentTime', 'favorites', 'directoryList', 'loading'],
  computed: {
    ...mapGetters([
      'userGuid',
      'playing',
      'playList',
      'currentIndex',
      'agencyGuid',
      'userCityName'
    ])
  },
  data () {
    return {
      sliderValue: 0,
      songReady: false, // 播放器准备就绪标记
      speedPopup: false, // 速率popup
      speedOptionIndex: 2, // 默认速率
      speedOption: [
        '0.50',
        '0.75',
        '1.00',
        '1.25',
        '1.50',
        '1.75',
        '2.00'
      ],
      timedOffPopup: false, // 定时关闭popup
      timeOffIndex: 0, // 默认关闭时间
      timedOffOption: [
        {
          name: '不开启',
          value: 0
        },
        {
          name: '15分钟后',
          value: 900
        },
        {
          name: '30分钟后',
          value: 1800
        },
        {
          name: '60分钟后',
          value: 3600
        },
        {
          name: '90分钟后',
          value: 5400
        },
        {
          name: '播完当前',
          value: 'now-over'
        }
      ],
      listPopup: false,
      swiperOptions: {
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',
          renderCustom: (swiper, current, total) => {
            let html = '<div class="swiper-page-block">'
            for (let i = 0; i < total; i++) {
              if (i === current - 1) {
                html += '<span class="my-bullet my-bullet-active"></span>'
              } else {
                html += '<span class="my-bullet"></span>'
              }
            }
            html += '</div>'
            return html
          },
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active'
        }
      },
      data: [],
      showSignFlag: false
    }
  },
  methods: {
    setofftime (index) {
      this.timeOffIndex = index
      let option = this.timedOffOption[index]
      this.$emit('setofftime', option)
      this.timedOffPopup = false
    },
    back () {
      this.$emit('closeaudio')
    },
    userFavorites () {
      this.$emit('favorites', this.favorites)
    },
    playerPause () {
      if (!this.loading) {
        if (this.auto_play) {
          this.$emit('tostop')
        } else {
          this.$emit('toloadAudio')
        }
      }
    },
    prev () {
      this.$emit('prev')
    },
    next () {
      this.$emit('next')
    },
    sliderStart () {
      this.$emit('tostop')
    },
    sliderEnd () {
      this.$emit('changeslider', this.sliderValue)
    },
    changeSliderValue (value) {
      let newval = Number(this.sliderValue) + Number(value)
      this.$emit('changeslider', newval)
    },
    format (val) {
      val = val | 0
      const minute = this._pad(val / 60 | 0)
      const second = this._pad(val % 60)
      return `${minute}:${second}`
    },
    _pad (num, n = 2) {
      let len = num.toString().length
      while (len < n) {
        num = '0' + num
        len++
      }
      return num
    },
    listPopupClick () {
      if (this.directoryList.length) {
        this.$emit('closeDirectory')
      } else {
        Toast.fail('暂无列表')
      }
    },
    ...mapMutations({
      setPlaying: 'SET_PLAYING',
      setFullScreen: 'SET_FULL_SCREEN',
      setCurrentIndex: 'SET_CURRENT_INDEX'
    })
  },
  watch: {
    currentTime (newVal) {
      this.sliderValue = newVal
    },
    playing (newPlaying) {
      const player = this.$refs.player
      this.$nextTick(() => {
        newPlaying ? player.play() : player.pause()
        if (player.paused !== !newPlaying) {
          this.setPlaying(!player.paused)
        }
      })
    },
    speedOptionIndex (index) {
      console.log('this.speedOption[index]', this.speedOption[index])
      let speed = this.speedOption[index]
      this.$emit('changespeed', speed)
      this.speedPopup = false
    },
    currentSong (song) {
      if (song) {
        this.$nextTick(() => {
          this.$refs.player.play()
        })
      } else {
        Toast.fail('暂无音频资源')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.swiper-pagination >>> .swiper-page-block
  display flex
  justify-content center
  align-items center
  width auto
  height 26px
  margin 0 auto
  border-radius 13px
  padding 0 16px

.swiper-pagination >>> .my-bullet
  display inline-block
  width 14px
  height 8px
  margin 0 7px
  background rgba(255, 255, 255, .3)
  border-radius 4px

.swiper-pagination >>> .my-bullet-active
  width 14px
  height 8px
  border-radius 4px
  background rgba(255, 255, 255, 1)

.drop-enter-active, .drop-leave-active
  transition: all 0.3s

.drop-enter, .drop-leave-to
  transform: translate3d(0, 100%, 0)

.player
  position fixed
  z-index 2000
  top 0
  left 0
  bottom 0
  right 0
  background rgba(0, 0, 0, 1)
  overflow hidden

  .bg-blur
    width 100%
    height 100%
    background-repeat no-repeat
    background-position center
    background-size cover
    filter blur(40px)
    -webkit-backdrop-filter: blur(40px)
    backdrop-filter: blur(40px)

  .wrapper
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background rgba(0, 0, 0, .4)

    .header
      display table
      width 100%
      height 144px

      .header-back
        display flex
        align-items center
        width 750px
        height 112px
        text-align center

        .icon-back-block
          display flex
          align-items center
          width 90px
          height 112px

          .icon-back
            width 28px
            height 15px
            margin-left 31px
            margin-right 31px
            bg-image('~@/assets/img/player-detail/icon-back')
            background-size 100% 100%
            background-repeat no-repeat

        .header-content
          width 570px
          font-size 40px
          font-weight bold
          color rgba(255, 255, 255, 1)

      .header-author
        display flex
        align-items center
        justify-content center
        font-size 30px
        color rgba(255, 255, 255, 1)

    .content
      display flex
      justify-content center
      align-items center
      width 100%
      height calc(100% - 610px)

      .swiper
        width 100%
        height 100%
        margin-top 40px

        .swiper-slide
          display flex
          justify-content center
          width 100%
          height 100%

          .cover-block
            position relative
            width 500px
            height 520px
            margin-top 30px

            .cover
              position absolute
              z-index 1
              width 360px
              height 500px
              border-radius 30px
              padding 10px
              background rgba(255, 255, 255, .6)
              overflow hidden

              .cover-img
                width 100%
                height 100%
                border-radius 30px

            .cd
              position absolute
              z-index 0
              top 10px
              left 8px
              width 492px
              height 492px
              bg-image('~@/assets/img/player-detail/cd')
              background-size 100% 100%
              background-repeat no-repeat

          .scroll
            width 594px
            height 576px
            background rgba(255, 255, 255, .2)
            border-radius 10px
            overflow hidden

            .desc
              display flex
              flex-wrap wrap
              justify-content center
              width 594px

              .desc-title
                width 534px
                height 44px
                margin-top 54px
                line-height 44px
                text-align center
                font-size 40px
                font-weight bold
                color rgba(255, 255, 255, 1)
                no-wrap()

              .desc-author
                width 534px
                height 34px
                line-height 38px
                font-size 30px
                color rgba(255, 255, 255, 1)
                margin-top 18px

              .desc-anchor
                width 534px
                height 34px
                line-height 38px
                font-size 30px
                color rgba(255, 255, 255, 1)

              .desc-content
                width 534px
                margin-top 30px
                padding-top 24px
                font-size 24px
                line-height 36px
                color rgba(255, 255, 255, .6)
                border-top 1px solid rgba(255, 255, 255, 1)
                overflow hidden
                height:320px;
                display: -webkit-box; /* 必须设置此项才能生效 */
                -webkit-line-clamp: 9; /* 限制最大行数 */
                -webkit-box-orient: vertical; /* 垂直布局 */
                overflow: hidden; /* 超出部分隐藏 */
                text-overflow: ellipsis; /* 显示省略号 */

      .swiper-pagination
        display flex
        top 608px

    .bottom
      position absolute
      left 0
      bottom 0
      width 100%
      height 426px

      .icon-list
        display flex
        justify-content space-around
        align-items center
        width 100%
        height 88px

        .icon-block
          display table
          width 64px
          height 88px

          .icon-block-icon
            width 54px
            height 54px
            margin 0 auto
            background-size 100% 100%
            background-repeat no-repeat

            &.icon-favorites
              bg-image('~@/assets/img/player-detail/icon-favorites')

              &.active
                bg-image('~@/assets/img/player-detail/icon-favorites-active')

            &.icon-timing
              bg-image('~@/assets/img/player-detail/icon-timing')

            &.icon-speed
              bg-image('~@/assets/img/player-detail/icon-speed')
              line-height 58px
              font-size 22px
              text-align center
              color rgba(255, 255, 255, 1)

            &.icon-lst
              bg-image('~@/assets/img/player-detail/icon-lst')

          .icon-block-content
            width 64px
            height 24px
            margin-top 10px
            line-height 24px
            text-align center
            font-size 22px
            color rgba(255, 255, 255, 1)

      .slider
        display flex
        align-items center
        width 648px
        height 28px
        margin 48px auto 0 auto

        .custom-button
          width 28px
          height 28px
          border-radius 50%
          box-shadow 0 3px 11px 0 rgba(0, 0, 0, 0.23)
          bg-image('~@/assets/img/player-detail/slider')
          background-size 100% 100%
          background-repeat no-repeat
          extend-click()

      .slider-desc
        display flex
        width 648px
        height 26px
        margin 6px auto 0 auto
        color rgba(187, 187, 187, 1)
        font-size 24px

        .now-slider
          width 50%
          text-align left

        .total-slider
          width 50%
          text-align right

      .operating
        display flex
        justify-content space-around
        width 100%
        height 200px
        margin-top 26px

        .icon-retreat
          width 54px
          height 50px
          margin-top 40px
          bg-image('~@/assets/img/player-detail/icon-retreat')
          background-size 100% 100%
          background-repeat no-repeat

        .icon-prev
          width 44px
          height 50px
          margin-top 40px
          bg-image('~@/assets/img/player-detail/icon-prev')
          background-size 100% 100%
          background-repeat no-repeat

        .icon-play
          width 130px
          height 130px
          bg-image('~@/assets/img/player-detail/play')
          background-size 100% 100%
          background-repeat no-repeat

          &.active
            bg-image('~@/assets/img/player-detail/stop')

        .icon-next
          width 44px
          height 50px
          margin-top 40px
          bg-image('~@/assets/img/player-detail/icon-next')
          background-size 100% 100%
          background-repeat no-repeat

        .icon-forward
          width 54px
          height 50px
          margin-top 40px
          bg-image('~@/assets/img/player-detail/icon-forward')
          background-size 100% 100%
          background-repeat no-repeat

      .icon-pause
        bg-image('~@/assets/img/player-detail/play')

  .speed-popup
    display flex
    flex-wrap wrap
    justify-content center
    align-items center
    width 100%
    background rgba(245, 245, 244, 1)

    .speed-block
      display flex
      flex-wrap wrap
      justify-content center
      align-items center
      width 100%
      padding 20px 0

      .speed-option
        display flex
        align-items center
        width 650px
        height 84px
        border-bottom 1px solid rgba(231, 231, 231, 1)

        &:last-child
          border-bottom none

        .speed
          width 608px
          height 34px
          line-height normal
          font-size 30px
          color rgba(51, 51, 51, 1)

          &.active
            color rgba(179, 53, 58, 1)

        .speed-active
          width 34px
          height 34px
          bg-image('~@/assets/img/player-detail/selected')
          background-size 100% 100%
          background-repeat no-repeat

    .speed-cancel
      width 650px
      height 30px
      padding 20px 0
      margin-bottom 40px
      line-height normal
      text-align center
      font-size 26px
      color rgba(153, 153, 153, 1)
      background rgba(255, 255, 255, 1)
      border-radius 35px

  .list-popup
    display flex
    flex-wrap wrap
    justify-content center
    width 750px
    height 910px

    .title
      width 660px
      padding-right 90px
      height 116px
      line-height 94px
      text-align right
      font-size 28px
      color rgba(51, 51, 51, 1)

    .popup-scroll
      width 750px
      height 684px
      overflow hidden

      .play-item
        display flex
        align-items center
        width 690px
        height 96px
        margin 0 auto
        border-bottom 1px solid rgba(231, 231, 231, 1)

        &:last-child
          border-bottom none

        .item-left
          width 600px
          padding-left 10px
          height 96px

          .item-name
            width 600px
            font-size 30px
            color rgba(51, 51, 51, 1)
            margin-top 18px
            no-wrap()

            &.active
              color rgba(179, 53, 58, 1)

          .item-time-block
            display flex
            align-items center
            width 600px
            margin-top 6px

            .item-time-icon
              width 14px
              height 14px
              bg-image('~@/assets/img/player-detail/time')
              background-size 100% 100%
              background-repeat no-repeat

            .item-time
              margin-left 10px
              line-height normal
              font-size 18px
              color rgba(153, 153, 153, 1)

        .item-right
          width 28px
          height 26px
          bg-image('~@/assets/img/player-detail/play-active')
          background-size 100% 100%
          background-repeat no-repeat

    .play-list-cancel
      width 650px
      height 30px
      padding 20px 0
      margin-bottom 40px
      line-height normal
      text-align center
      font-size 26px
      color rgba(153, 153, 153, 1)
      background rgba(255, 255, 255, 1)
      border-radius 35px
</style>
