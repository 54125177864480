<template>
  <div class="wrapper">
    <div class="bottom">
      <div class="wrapper" @click="favorites">
        <div class="icon icon-favorites" :class="{active: favoritesActive}"></div>
        <div class="name" v-show="!favoritesActive">收藏</div>
        <div class="name" v-show="favoritesActive">已收藏</div>
      </div>
      <div class="wrapper" @click="share">
        <div class="icon icon-share"></div>
        <div class="name">分享</div>
      </div>
      <div class="wrapper" @click="showFont = !showFont">
        <div class="icon icon-font"></div>
        <div class="name">字号</div>
      </div>
      <div class="wrapper" @click="directory">
        <div class="icon icon-directory"></div>
        <div class="name">目录</div>
      </div>
    </div>
    <popup v-model="showFont" position="bottom" duration="0.2" class="font-block">
      <div class="desc">字号</div>
      <div class="size" :class="{active: fontIndex == 1}" @click="sizeAdd">A+</div>
      <div class="size" :class="{active: fontIndex == 2}" @click="sizeLess">A-</div>
    </popup>
  </div>
</template>

<script>
import { Popup, Toast } from 'vant'

export default {
  name: 'EbookDetailQqBottom',
  props: {
    directoryList: {
      type: Array,
      default () {
        return []
      }
    },
    favoritesActive: {
      type: Boolean,
      default: false
    },
    showDirectory: {
      type: Boolean,
      default: false
    },
    nowCid: {
      type: String,
      default: ''
    }
  },
  components: {
    Popup
  },
  data () {
    return {
      showFont: false,
      fontIndex: 0,
      directorySort: true,
      data: []
    }
  },
  methods: {
    sizeAdd () {
      this.fontIndex = 1
      this.$emit('sizeAdd')
    },
    sizeLess () {
      this.fontIndex = 2
      this.$emit('sizeLess')
    },
    directory () {
      if (this.directoryList.length) {
        this.$emit('closeDirectory', this.showDirectory)
      } else {
        Toast.fail('暂无目录')
      }
    },
    changePage (page) {
      this.showDirectory = !this.showDirectory
      this.$emit('changePage', page)
    },
    favorites () {
      this.$emit('favorites', this.favoritesActive)
    },
    share () {
      this.$emit('share')
    }
  },
  watch: {
    directorySort () {
      this.$emit('directorySort')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  width 100%

  .bottom
    position fixed
    justify-content space-around
    align-items center
    bottom 0
    width 750px
    height 97px
    display flex
    border-top 1px solid #EEEEEE
    padding-bottom constant(safe-area-inset-bottom)
    padding-bottom env(safe-area-inset-bottom)
    background #FFFFFF
    z-index 1

    .wrapper
      display block
      align-items center
      width 186px
      height 48px
      text-align: center;

      &:first-child
        border-left none

      .icon
        width 29px
        height 28px
        margin: 0 auto;
        background-size 100% 100%
        background-repeat no-repeat
        margin-bottom:10px;

        &.icon-favorites
          bg-image('~@/assets/img/book-detail/favorites-icon')

        &.icon-share
          bg-image('~@/assets/img/book-detail/share')

        &.icon-font
          bg-image('~@/assets/img/ebook-detail/font')

        &.icon-directory
          bg-image('~@/assets/img/ebook-detail/directory')

        &.active
          bg-image('~@/assets/img/book-detail/favorites-icon-active')

      .name
        width 100px
        line-height normal
        font-size 26px
        font-weight 500
        color #808080
        margin: 0 auto;

  .font-block
    display flex
    align-items center
    width 750px
    height 138px
    border-radius 30px 30px 0 0
    background rgba(245, 245, 244, 1)

    .desc
      width 145px
      height 38px
      padding-left 20px
      margin-left 30px
      line-height 38px
      font-weight bold
      font-size 34px
      color rgba(51, 51, 51, 1)
      border-left 8px solid rgba(179, 53, 58, 1)

    .size
      width 230px
      height 54px
      margin-right 30px
      line-height 54px
      text-align center
      font-size 26px
      box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
      border-radius 10px
      background rgba(255, 255, 255, 1)
      border 1px solid rgba(179, 53, 58, 0)

      &.active
        border 1px solid rgba(179, 53, 58, 1)
        color rgba(179, 53, 58, 1)

  .directory-block
    width 680px
    height 100%

    .header
      display flex
      width 620px
      height 82px
      margin-left 30px
      border-bottom 1px solid rgba(245, 245, 244, 1)

      .header-name
        width 423px
        height 38px
        margin-top 30px
        padding-left 22px
        line-height 38px
        font-size 34px
        font-weight bold
        color rgba(51, 51, 51, 1)
        border-left 8px solid rgba(179, 53, 58, 1)

      .chapter
        display flex
        width 174px
        height 26px
        margin-top 34px

        .chapter-text
          width 146px
          height 26px
          line-height 26px
          font-weight 500
          font-size 26px
          color rgba(153, 153, 153, 1)

        .chapter-icon
          width 28px
          height 20px
          margin-top 3px
          background-size 100% 100%
          background-repeat no-repeat

          &.asc
            bg-image('~@/assets/img/ebook-detail/asc')

          &.desc
            bg-image('~@/assets/img/ebook-detail/desc')

    .scroll
      position absolute
      top 84px
      left 0
      right 0
      bottom 0
      overflow hidden

      .directory-list
        width 620px
        margin-left 30px

        .directory-item
          width 590px
          padding 30px 0 30px 30px
          line-height normal
          font-weight 500
          font-size 30px
          color rgba(51, 51, 51, 1)
          border-bottom 1px solid rgba(245, 245, 244, 1)

          &:last-child
            border-bottom none
</style>
